<template>
  <div class="explain-page">
    <h3 class="title">截图导入说明</h3>
    <div class="content">
      <p>
        1、点击对应的识别结果可对结果进行编辑。
      </p>
      <p>2、每次导入最多支持5张图片，超过5张需分多次导入。</p>
      <p>3、上传照片后支持根据账单名称智能识别账单：类别 、金额、时间、账户名称、收入/支出。</p>
      <p>4、默认将记账流水导入到上次记账的账本，如需修改可在首页切换账本后再使用导入功能，账户默认使用您选择的导入方式，
          备注内容默认为识别的账单名称，记账成员默认使用【我】，暂不支持自动录入位置及照片。</p>
      <p>5、记账流水中有相同时间、金额的流水，在识别结果中会展示重复账单，默认不勾选。</p>
      <p>6、识别完成。解析成功后，会为您匹配好相关记账元素。生成账单记录并默认勾选，确认无误后，点击【导入】即可成功保存记账流水。</p>
      <p>7、目前仅日常账本的默认类别支持多场景名词的匹配如麻辣烫、海底捞等会自动匹配到餐饮，但其它如生意账本、
        旅行账本需要识别到类别的具体名称方可确保类别无误，其它账本的关联词暂不支持模糊匹配。</p>
      <p>8、目前仅支持识别中文，英文、数字、常见符号，暂不支持特殊字符识别，我们后续会开发其它账本的类别、文字等对其进行智能匹配，为您提供更好的记账体验，敬请期待。</p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'IntroExplain',
  created() {
    document.title = '截图导入说明';
  },
};
</script>
<style scoped lang="scss">
  .explain-page {
    padding: 0 10px 20px 10px;
    font: 24px "PingFang SC Semibold";
    .title {
      margin: 20px 0;
      font-size: 32px;
    }
    .content {
      p {
        margin: 15px 0;
      }
      ul {
        margin-left: 20px;
        li {
          margin: 8px 0;
          line-height: 35px;
        }
      }
    }
  }
</style>
